import 'materialize-css/dist/js/materialize.js';
import Vue from 'vue/dist/vue.esm';

import VueResource from 'vue-resource';
Vue.use(VueResource);

import VueCanCan from 'vue-cancan';

import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);

import Moment from 'vue-moment';
Vue.use(Moment);

import { i18n } from '../i18n-js/index.js';

import Home from'../views/project/home.vue';

document.addEventListener('turbolinks:load', () => {
  // #TODO: fix error, dont render on Vue, need window.onload
  // window.onload = function () { 
  Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name=csrf-token]')
                                                    .getAttribute('content');
  // }

  const element = document.getElementById('vue_home');
  let props = null;

  if (element != null) props = JSON.parse(element.getAttribute('data'));

  if (element != null && props != null) {
    props.events = JSON.parse(props.events);
    props.aircrafts = JSON.parse(props.aircrafts);
    props.current_account = JSON.parse(props.current_account);
    props.current_user = JSON.parse(props.current_user);
    Vue.use(VueCanCan, { rules: JSON.parse(props.abilities) });

    const app = new Vue({
      el: '#vue_home',
      components: {
        home: Home,
      },
      i18n,
      render: h => h(Home, { props }),
    });
  }

});
