<template>
  <div id="home_app" class="row">
    <cardindex :title='{"title": $t(`${cas}.event.events`) }' :setSize="'s12 xl6'" :thisRow="''"
                v-can.search_events.project>
      <eventstable slot="table"
                   :initial_events="events"
                   :initial_account="current_account"
      ></eventstable>
    </cardindex>
    <cardindex :title='{"title": $t(`${cas}.aircraft.aircrafts`)}' :setSize="'s12 m6 xl3'" :thisRow="''"
                v-can.search_aircrafts.project>
      <aircraftstable slot="table"
                   :initial_aircrafts="aircrafts"
                   :initial_account="current_account"
      ></aircraftstable>
    </cardindex>
    <cardindex :title='{"title": $t(`${cas}.time_tracker.time_track`)}' :setSize="'s12 m6 xl3'" :thisRow="''"
                v-if='is_super_admin(current_user)' v-can.search_time_users.project>
      <timetrackertable slot="table"></timetrackertable>
    </cardindex>
    <cardindex :title='{"title": $t(`${cas}.user.pilots`)}' :setSize="'s12 m6 xl3'" :thisRow="''"
                v-can.search_pilot_users.project>
      <userstable slot="table"></userstable>
    </cardindex>
  </div>
</template>

<script>
import CardIndex from '../shared/index.vue';
import EventsTable from './events_warning_table.vue';
import AircraftsTable from './aircraft_warning_table.vue';
import TimeTrackerTable from './time_tracker_table.vue';
import UsersTable from './users_warning_table.vue';

export default {
  components: {
    'cardindex': CardIndex,
    'eventstable': EventsTable,
    'aircraftstable': AircraftsTable,
    'timetrackertable': TimeTrackerTable,
    'userstable': UsersTable,
  },
  props:['events', 'aircrafts', 'time_users', 'current_account', 'current_user'],
  data: function () {
    return {
      current_events: null,
      cas: this.current_account.account_type
    }
  },
  created() {
    this.current_events = this.events
  },
  updated: function() {
    M.updateTextFields();
  },
  methods: {
    is_super_admin: function(user) {
      return user.role == 'super_admin' && user.level == 1
    },
    aircraft_info: function(aircraft) {
     return `${aircraft.aircraft_type} - ${aircraft.registration}`;
    }
  },
}
</script>
