<template>
  <!-- TODO: en home mostrar solo usuarios activos -->
  <div >
    <div class="no-border">
      <div class="input-field col s10">
        <input id="search_user" placeholder="Search" type="text" v-model="search_in_users" class="validate">
        <span class="helper-text error_text" data-error="hello"/>
      </div>
      <a id="search_user_btn" class="button_search_home waves-effect waves-light btn col s2 l2" @click="search_pilot_users(search_in_users)" ><i class="material-icons">search</i></a>
    </div>
    <div class="user_home no-border row fixed_height">
      <table class="striped col s12">
        <tbody>
          <tr :id="`user_${user.id}`" v-for="(user, index) in users">
            <a :href="`/users/${user.id}`">
              <td class="truncate" v-if="!!user.name">{{ user.name }}</td>
              <td class="truncate" v-else>{{ user.email }}</td>
            </a>
            <td class="right">
              <warningLogos :warning_level="user.warning_level" :warning_text="user.warning_text"></warningLogos>
            </td>
            </a>
          </tr>
        </tbody>
      </table>
      <pagination :links='current_links'
                  :response_atr="'users'"
                  :params='`like=${search_in_users}`'></pagination>
    </div>
  </div>
</template>

<script>
import warningLogos from '../shared/warning_logo';
import pagination from '../shared/pagination';
import { applicationMixin } from '../../mixins/application_mixin';

export default {
  components: {
    'warningLogos': warningLogos,
    'pagination': pagination,
  },
  props:['initial_aircrafts', 'initial_account'],
  data: function () {
    return {
      users: null,
      search_in_users: '',
      current_links: null,
    }
  },
  created() {
    this.search_pilot_users(this.search_in_users)
  },
  updated: function() {
    M.updateTextFields();
  },
  methods: {
    search_pilot_users: function(like){
      this.$http.get(`/en/project/search_pilot_users?like=${like}&page%5Bnumber%5D=1`).then(response => {
        this.users = response.body.users
        this.$children[0].this_links = response.body.links
      })
    },
  },
  mixins: [applicationMixin]
}
</script>
