<template>
<div id="pagination">
  <div class="col s12 bottom_div no-border">
    <ul class="pagination" v-show="this_links.total_pages > 1">
      <li v-if="!!this_links.prev" class="previous_page">
        <a @click="call(this_links.prev)">
          <i class="material-icons">chevron_left</i>
        </a>
      </li>
      <li v-else class="previous_page disabled">
        <a href="#!" @click.prevent>
          <i class="material-icons">chevron_left</i>
        </a>
      </li>
      <a v-for="n in this_links.total_pages" @click="page_call(n)">
        <li :class="['waves-effect', { 'active' : n == this_links.current_page }]">{{n}}</li>
      </a>
      <li v-if="!!this_links.next" class="next_page">
        <a rel="next" @click="call(this_links.next)">
          <i class="material-icons">chevron_right</i>
        </a>
      </li>
      <li v-else class="next_page disabled">
        <a rel="next" href="#" @click.prevent>
          <i class="material-icons">chevron_right</i>
        </a>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  data: function() {
    return {
      this_links: {
        next: null,
        prev: null,
        total_pages: 0
      }
    }
  },
  props: ['links', 'params', 'response_atr'],
  created() {
    if (!!this.links ) this.this_links = this.links
  },
  updated: function() {
    if (!!this.links ) this.this_links = this.links
  },
  methods: {
    page_call: function(page) {
      this.call(`${this.this_links.url}?page%5Bnumber%5D=${page}`)
    },
    call: function(action) {
      this.$http.get(`${action}&${this.params}`).then(response => {
        this.$parent[this.response_atr] = response.body[this.response_atr]
        this.this_links = response.body.links
      });
    }
  }
}
</script>
