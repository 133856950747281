<template>
  <div >
    <div class="no-border">
      <div class="input-field col s10">
        <input id="search_event" placeholder="Search" type="text" v-model="search_in_aircrafts" class="validate">
        <span class="helper-text error_text" data-error="hello"/>
      </div>
      <a id="search_events_btn" class="button_search_home waves-effect waves-light btn col s2 l2" @click="search_aircrafts(search_in_aircrafts)" ><i class="material-icons">search</i></a>
    </div>
    <div class="no-border row fixed_height">
      <table class="striped col s12">
        <tbody>
          <tr v-for="(aircraft, index) in aircrafts">
            <a :href="`/aircrafts/${aircraft.id}`">
              <td>{{ aircraft_info(aircraft) }}</td>
            </a>
            <td class="right">
              <warningLogos :warning_level="aircraft.warning_level" :warning_text="aircraft.warning_text"></warningLogos>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination :links='current_links'
                  :response_atr="'aircrafts'"
                  :params='`like=${search_in_aircrafts}`'></pagination>
    </div>
  </div>
</template>

<script>
import warningLogos from '../shared/warning_logo';
import pagination from '../shared/pagination';
import { applicationMixin } from '../../mixins/application_mixin';

export default {
  components: {
    'warningLogos': warningLogos,
    'pagination': pagination,
  },
  props:['initial_aircrafts', 'initial_account'],
  data: function () {
    return {
      aircrafts: null,
      search_in_aircrafts: '',
      current_links: null,
    }
  },
  created() {
    this.search_aircrafts(this.search_in_aircrafts)
  },
  updated: function() {
    M.updateTextFields();
  },
  methods: {
    search_aircrafts: function(like){
      this.$http.get(`/en/project/search_aircrafts?like=${like}&page%5Bnumber%5D=1`).then(response => {
        this.aircrafts = response.body.aircrafts
        this.$children[0].this_links = response.body.links
      })
    },
    aircraft_info: function(aircraft){
     return `${aircraft.aircraft_type} - ${aircraft.registration}`;
    }
  },
  mixins: [applicationMixin]
}
</script>
