<template>
  <div >
    <div class="no-border">
      <div class="input-field col s5">
        <input id="search_from_date_home" placeholder="From Date" type="text" v-model="search.search_from_date" class="validate date search_from_date">
        <span class="helper-text error_text" :data-error="error_text.search_from_date"/>
      </div>

      <div class="input-field col s5">
        <input id="search_to_date_home" placeholder="To Date / Optional" type="text" v-model="search.search_to_date" class="validate date search_to_date">
        <span class="helper-text error_text" :data-error="error_text.search_to_date"/>
      </div>

      <a id="search_events_btn_home" class="button_search_home waves-effect waves-light btn col s2 " @click="search_events(search.search_from_date, search.search_to_date)"><i class="material-icons">search</i></a>
    </div>
    <div class="no-border row fixed_height">
      <table class="striped col s12">
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <tbody>
          <tr v-for="(event, index) in events">
            <td>{{ aircraft_info(event) }}</td>
            <td>{{ event.user_list }}</td>
            <td>{{ dateTimeHandlerMix(event.from_flight_date, 'date_time') }}</td>
            <td>
              <warningLogos :warning_level="event.warning_level" :warning_text="event.warning_text"></warningLogos>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination :links='current_links'
                  :response_atr="'events'"
                  :params='`from=${search.search_from_date}&to=${search.search_to_date}`'></pagination>
    </div>
  </div>
</template>

<script>
import warningLogos from '../shared/warning_logo';
import pagination from '../shared/pagination';
import { applicationMixin } from '../../mixins/application_mixin';

export default {
  components: {
    'warningLogos': warningLogos,
    'pagination': pagination,
  },
  props:['initial_events', 'initial_account'],
  data: function () {
    return {
      events: null,
      search: {
        search_from_date: '',
        search_to_date: '',
      },
      error_text: {},
      picker: {
        fromDatePikerinstances: null,
        toDatePikerinstances: null,
      },
      current_links: null,
    }
  },
  created() {
    this.search.search_from_date = this.dateTimeHandlerMix(new Date(), 'date')
    this.search.search_to_date = this.dateTimeHandlerMix(new Date(), 'date')
    this.search_events(this.search.search_from_date, this.search.search_to_date)
  },
  mounted: function() {
    // materializecss DatePicker
    if (this.picker.fromDatePikerinstances === null) {
      var searchFromDatePiker = document.querySelectorAll('#search_from_date_home');
      var searchToDatePiker = document.querySelectorAll('#search_to_date_home');
      let vm = this

      this.picker.fromDatePikerinstances = M.Datepicker.init(searchFromDatePiker, {
        showClearBtn: true,
        autoClose: true,
        onClose: function() {
          vm.search.search_from_date = this.$el[0].value
          vm.picker.toDatePikerinstances.options.minDate = vm.time_generator(vm.search.search_from_date);
        }
      })[0];
      this.picker.toDatePikerinstances = M.Datepicker.init(searchToDatePiker, {
        showClearBtn: true,
        autoClose: true,
        onClose: function() {
          vm.search.search_to_date = this.$el[0].value
          vm.picker.fromDatePikerinstances.options.maxDate = vm.time_generator(vm.search.search_to_date);
        }
      })[0];
    }
  },
  updated: function() {
    M.updateTextFields();
  },
  methods: {
    search_events: function(from_date, to_date) {
      this.$http.get(`/en/project/search_events?from=${from_date}&to=${to_date}&page%5Bnumber%5D=1`).then(response => {
        this.events = response.body.events
        this.$children[0].this_links = response.body.links
      });
    },
    aircraft_info: function(event) {
     if (!!event.aircraft && event.aircraft.id == null ) return 'No Aircraft assigned'
     return `${event.aircraft.aircraft_type} - ${event.aircraft.registration}`;
    }
  },
  mixins: [applicationMixin]
}
</script>
