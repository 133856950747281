<template>
  <div class="time_tracker">
    <div class="no-border">
      <div class="input-field col s10">
        <input id="search_time_user" placeholder="Search" type="text" v-model="search_in_users" class="validate">
        <span class="helper-text error_text" data-error="hello"/>
      </div>
      <a id="search_time_user_btn" class="button_search_home waves-effect waves-light btn col s2 l2" @click="search_time_users(search_in_users)" ><i class="material-icons">search</i></a>
    </div>
    <div class="row no-border fixed_height">
      <table class="striped col s12">
        <col width="60%">
        <col width="10%">
        <col width="30%">
        <tbody>
          <tr v-for="(time_user, index) in time_users">
            <a :href="`/users/${time_user.id}`">
              <td class="truncate" v-if="!!time_user.name">{{ time_user.name }}</td>
              <td class="truncate" v-else>{{ time_user.email }}</td>
            </a>
            <td>
              {{ time_user.time_tracker.hours_today }}hr.
            </td>
            <td class="check_in_btn right">
              <a id="check_in_btn"
                class="waves-effect waves-light btn btn_home_in_out"
                @click="check_in_check_out(`/en/time_tracker/check_in?user_id=${time_user.id}`,time_user)"
                v-show="time_user.time_tracker.time_out != null || time_user.time_tracker.time_in == null">
                in
              </a>

              <a id="check_in_btn"
                class="waves-effect waves-light btn btn_home_in_out"
                @click="check_in_check_out(`/en/time_tracker/check_out?user_id=${time_user.id}`,time_user)"
                v-show="time_user.time_tracker.time_out == null && time_user.time_tracker.time_in != null">
                out
              </a>

            </td>
          </tr>
        </tbody>
      </table>
      <pagination :links='current_links'
                  :response_atr="'time_users'"
                  :params='`like=${search_in_users}`'></pagination>
    </div>
  </div>
</template>

<script>
import pagination from '../shared/pagination';
import { applicationMixin } from '../../mixins/application_mixin';

export default {
  components: {
    'pagination': pagination,
  },
  props:['initial_aircrafts', 'initial_account'],
  data: function () {
    return {
      time_users: null,
      search_in_users: '',
      current_links: null,
    }
  },
  created() {
    this.search_time_users(this.search_in_users)
  },
  updated: function() {
    M.updateTextFields();
  },
  methods: {
    search_time_users: function(like){
      this.$http.get(`/en/project/search_time_users?like=${like}&page%5Bnumber%5D=1`).then(response => {
        this.time_users = response.body.time_users
        this.$children[0].this_links = response.body.links
      })
    },
    check_in_check_out: function(url, time_user){
      this.$http.get(url).then(response => {
        this.findObjectByIdMix (this.time_users, response.body.user_id).time_tracker = response.body
      })
    }
  },
  mixins: [applicationMixin]
}
</script>
